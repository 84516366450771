import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "saveljevs_mantova" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Midfielder of the National Team of Latvia Aleksejs Saveljevs signed a one-year contract with the AC  Mantova 1911. AC Mantova 1911 will play this season in the Italian Serie C. Catenaccio congratulates Aleksejs and wishes him good luck in his new club!</p>
        </div>
      </div>
    )
  }
}